import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
//Pages
import HomePage from './pages/HomePage'
import BrowsePage from './pages/BrowsePage'
import SectorPage from './pages/SectorPage'
import CoinsPage from './pages/CoinsPage'
import SingleCoinPage from './pages/SingleCoinPage'
import MyIndexesPage from './pages/MyIndexesPage'
import MyFavoritesPage from './pages/MyFavoritesPage'
import ComparePage from './pages/ComparePage'
import CryptoPage from './pages/CryptoPage'
import CreateIndexPage from './pages/CreateIndexPage'
import SettingsPage from './pages/SettingsPage'
import PrivacyPolicyPage from './pages/PrivacyPolicyPage'
import TermsPage from './pages/TermsPage'
import AltcoinSeasonPage from './pages/AltcoinSeasonPage'
import APIDashboard from './pages/APIDashboard'
import PaymentSuccess from './pages/PaymentSuccess'
import PaymentFailed from './pages/PaymentFailed'
import Helmet from "react-helmet"

//
import ResetPasswordPage from './pages/ResetPasswordPage'
import EmailConfirmPage from './pages/EmailConfirmPage'

//layout components
import SidePanel from './components/SidePanel'
import TopBar from './components/TopBar'
import BFModal from './components/BFModal'

import { useState } from 'react'
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ReactGA from 'react-ga4';
// ReactGA.initialize('G-4EBY82N5RN');
ReactGA.initialize('G-GWD1D3BPK2');

const MainLayout = ({ showModalType, setShowModalType, children }) => {
  return(
    <>
      <ToastContainer />
      {showModalType && <BFModal showModalType={showModalType} setShowModalType={setShowModalType} />}
      <div className="flex flex-row relative">
        <div className="fixed z-50">
          <SidePanel setShowModalType={setShowModalType} />
        </div>
        <div className="w-full h-screen overflow-y">
          <div className="fixed top-0 z-40 md:pl-64 w-full">
            <TopBar showModalType={showModalType} setShowModalType={setShowModalType} />
          </div>
          <div className="mt-24 md:mt-14 md:ml-64 relative">
            {children}
          </div>
        </div>
      </div>
    </>
  )
}

const Error404 = () => {
	return (
		<div className="m-4 pt-3">
			<Helmet>
				<meta charSet="utf-8" />
				<meta name="robots" content="noindex" />
				<title>Error 404 - Page Not Found</title>
				<link rel="canonical" href="https://www.bitformance.com" />
				<meta
				  name="description"
				  content="Track, analyze, and create cryptocurrency indexes on one platform, for free. Choose from over 500+ cryptocurrencies and compare performance metrics with ease."
				/>
			</Helmet>
			<div class="error_container">
				<div class="error_heading">
					<h1>404</h1>
					<h3>Error - Page Not Found</h3>
				</div>
				<hr />
				<div class="error_section">
					<h3>Please check the URL.</h3>
					<p>Otherwise, <a href="https://www.bitformance.com">click here</a> to be redirected to the homepage.</p>
					<p>If the homepage isn't available, please contact <strong>contact@bitformance.com</strong> for support.</p>
				</div>
			</div>
		</div>
	)
}

function App() {


  ReactGA.send({ hitType: "pageview", page: "/", title: "Home" });
  ReactGA.send({ hitType: "pageview", page: "/coins", title: "View Coins" });
  ReactGA.send({ hitType: "pageview", page: "/coins/:symbol", title: "View Single Coin" });
  ReactGA.send({ hitType: "pageview", page: "/indexes/browse", title: "View Public Indexes" });
  ReactGA.send({ hitType: "pageview", page: "/indexes/browse/:id", title: "View Single Public Index" });
  ReactGA.send({ hitType: "pageview", page: "/indexes/my-indexes", title: "View User Indexes" });
  ReactGA.send({ hitType: "pageview", page: "/indexes/my-indexes/:id", title: "View Single User Index" });
  ReactGA.send({ hitType: "pageview", page: "/indexes/my-favorites", title: "View User Favorites" });
  ReactGA.send({ hitType: "pageview", page: "/compare", title: "Compare" });
  ReactGA.send({ hitType: "pageview", page: "/indexes/create-index", title: "Create/Edit Index" });
  ReactGA.send({ hitType: "pageview", page: "/settings", title: "View Settings" });
  ReactGA.send({ hitType: "pageview", page: "/privacy-policy", title: "Privacy Policy" });
  ReactGA.send({ hitType: "pageview", page: "/terms-and-conditions", title: "View T&C" });
  ReactGA.send({ hitType: "pageview", page: "/reset-link/:token", title: "Reset Password" });
  ReactGA.send({ hitType: "pageview", page: "/email-confirmed/:token", title: "Email Confirmation" });
  ReactGA.send({ hitType: "pageview", page: "/indexes/sectors", title: "Browse Sector Indexes" });
  ReactGA.send({ hitType: "pageview", page: "/Altcoin-Season-Index", title: "Altcoin Season Index" });
  ReactGA.send({ hitType: "pageview", page: "/API-Dashboard", title: "API Dashboard" });

  const [showModalType, setShowModalType] = useState('')

  //TODO: add auth guard to my indexes, my favorites
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={
            <MainLayout showModalType={showModalType} setShowModalType={setShowModalType}>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Bitformance: Track, Analyze, & Create Cryptocurrency Indexes</title>
					<link rel="canonical" href="http://mysite.com/example" />
					<meta
					  name="description"
					  content="Track, analyze, and create cryptocurrency indexes on one platform, for free. Choose from over 500+ cryptocurrencies and compare performance metrics with ease."
					/>
				</Helmet>
                <HomePage showModalType={showModalType} setShowModalType={setShowModalType}/>
            </MainLayout>
          }/>

		  <Route path="*" element={
			  <MainLayout showModalType={showModalType} setShowModalType={setShowModalType}>
				<Error404/>
			 </MainLayout>
		  }/>

          <Route exact path="/Home" element={
            <MainLayout showModalType={showModalType} setShowModalType={setShowModalType}>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Bitformance: Track, Analyze, & Create Cryptocurrency Indexes</title>
					<link rel="canonical" href="http://mysite.com/example" />
					<meta
					  name="description"
					  content="Track, analyze, and create cryptocurrency indexes on one platform, for free. Choose from over 500+ cryptocurrencies and compare performance metrics with ease."
					/>
				</Helmet>
                <HomePage showModalType={showModalType} setShowModalType={setShowModalType}/>
            </MainLayout>
          }/>

          <Route exact path="/coins" element={
            <MainLayout showModalType={showModalType} setShowModalType={setShowModalType}>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Explore Cryptocurrencies</title>
					<link rel="canonical" href="http://mysite.com/example" />
					<meta
					  name="description"
					  content="Browse over 500+ of the most popular cryptocurrencies and sort by market cap, volume, price, and more."
					/>
				</Helmet>
                <CoinsPage />
            </MainLayout>
          }/>

          <Route exact path="/coins/:symbol" element={
            <MainLayout showModalType={showModalType} setShowModalType={setShowModalType}>
                <SingleCoinPage />
            </MainLayout>
          }/>

		  <Route path="/coins/*" element={
			  <MainLayout showModalType={showModalType} setShowModalType={setShowModalType}>
				<Error404/>
			 </MainLayout>
		  }/>

          <Route exact path="/indexes/browse" element={
            <MainLayout showModalType={showModalType} setShowModalType={setShowModalType}>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Browse Bitformance Indexes</title>
					<link rel="canonical" href="http://mysite.com/example" />
					<meta
					  name="description"
					  content="Discover premade cryptocurrency indexes on our Browse page. Explore curated indexes for effortless tracking and valuable investment insights"
					/>
				</Helmet>
                <BrowsePage indexType={'bitformance'}/>
            </MainLayout>
          }/>

          <Route exact path="/indexes/sectors" element={
            <MainLayout showModalType={showModalType} setShowModalType={setShowModalType}>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Browse Sector Indexes</title>
					<link rel="canonical" href="http://mysite.com/example" />
					<meta
					  name="description"
					  content="Discover premade cryptocurrency indexes on our Browse page. Explore curated indexes for effortless tracking and valuable investment insights"
					/>
				</Helmet>
                <SectorPage/>
            </MainLayout>
          }/>

		  <Route path="/indexes/*" element={
			  <MainLayout showModalType={showModalType} setShowModalType={setShowModalType}>
				<Error404/>
			 </MainLayout>
		  }/>

          <Route exact path="/indexes/browse/:id" element={
            <MainLayout showModalType={showModalType} setShowModalType={setShowModalType}>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Browse Cryptocurrency Indexes</title>
					<link rel="canonical" href="http://mysite.com/example" />
					<meta
					  name="description"
					  content="Discover premade cryptocurrency indexes on our Browse page. Explore curated indexes for effortless tracking and valuable investment insights"
					/>
				</Helmet>
                <CryptoPage isAuth={false} />
            </MainLayout>
          }/>

		  <Route path="/indexes/browse/*" element={
			  <MainLayout showModalType={showModalType} setShowModalType={setShowModalType}>
				<Error404/>
			 </MainLayout>
		  }/>

          <Route exact path="/indexes/my-indexes" element={
            <MainLayout showModalType={showModalType} setShowModalType={setShowModalType}>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Browse Cryptocurrency Indexes</title>
					<link rel="canonical" href="http://mysite.com/example" />
					<meta
					  name="description"
					  content="Discover premade cryptocurrency indexes on our Browse page. Explore curated indexes for effortless tracking and valuable investment insights"
					/>
				</Helmet>
                <MyIndexesPage />
            </MainLayout>
          }/>

          <Route exact path="/indexes/my-indexes/:id" element={
            <MainLayout showModalType={showModalType} setShowModalType={setShowModalType}>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Browse Cryptocurrency Indexes</title>
					<link rel="canonical" href="http://mysite.com/example" />
					<meta
					  name="description"
					  content="Discover premade cryptocurrency indexes on our Browse page. Explore curated indexes for effortless tracking and valuable investment insights"
					/>
				</Helmet>
                <CryptoPage isAuth={true} />
            </MainLayout>
          }/>

		  <Route path="/indexes/my-indexes/*" element={
			  <MainLayout showModalType={showModalType} setShowModalType={setShowModalType}>
				<Error404/>
			 </MainLayout>
		  }/>

          <Route exact path="/indexes/my-favorites" element={
            <MainLayout showModalType={showModalType} setShowModalType={setShowModalType}>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Browse Cryptocurrency Indexes</title>
					<link rel="canonical" href="http://mysite.com/example" />
					<meta
					  name="description"
					  content="Discover premade cryptocurrency indexes on our Browse page. Explore curated indexes for effortless tracking and valuable investment insights"
					/>
				</Helmet>
                <MyFavoritesPage />
            </MainLayout>
          }/>

          <Route exact path="/compare" element={
            <MainLayout showModalType={showModalType} setShowModalType={setShowModalType}>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Compare Cryptocurrencies & Cryptocurrency Indexes</title>
					<link rel="canonical" href="http://mysite.com/example" />
					<meta
					  name="description"
					  content="Compare Cryptocurrencies & Cryptocurrency Indexes and discover performance trends, correlations, and more."
					/>
				</Helmet>
                <ComparePage />
            </MainLayout>
          }/>

          <Route exact path="/indexes/create-index" element={
            <MainLayout showModalType={showModalType} setShowModalType={setShowModalType}>
              <CreateIndexPage />
            </MainLayout>
          }/>
          <Route exact path="/settings" element={
            <MainLayout showModalType={showModalType} setShowModalType={setShowModalType}>
              <SettingsPage setShowModalType={setShowModalType} />
            </MainLayout>
          }/>

          <Route exact path="/privacy-policy" element={
            <MainLayout showModalType={showModalType} setShowModalType={setShowModalType}>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Privacy and Cookie Policy</title>
					<link rel="canonical" href="http://mysite.com/example" />
					<meta
					  name="description"
					  content="Bitformance values your privacy. Our transparent privacy and cookie policy ensures a secure and personalized experience as you use our platform."
					/>
				</Helmet>
                <PrivacyPolicyPage />
            </MainLayout>
          }/>

          <Route exact path="/terms-and-conditions" element={
            <MainLayout showModalType={showModalType} setShowModalType={setShowModalType}>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Terms of Service</title>
					<link rel="canonical" href="http://mysite.com/example" />
					<meta
					  name="description"
					  content="Read more about the terms & conditions for using Bitformance and your rights and responsibilities as a user."
					/>
				</Helmet>
                <TermsPage/>
            </MainLayout>
          }/>

          <Route exact path="/reset-link/:token" element={
			  <MainLayout showModalType={showModalType} setShowModalType={setShowModalType}>
				<ResetPasswordPage />
			  </MainLayout>
          }/>

		  <Route path="/reset-link/*" element={
			  <MainLayout showModalType={showModalType} setShowModalType={setShowModalType}>
				<Error404/>
			 </MainLayout>
		  }/>

          <Route exact path="/email-confirmed/:token" element={
			<MainLayout showModalType={showModalType} setShowModalType={setShowModalType}>
			  <EmailConfirmPage />
			</MainLayout>
          }/>

		  <Route path="/email-confirmed/*" element={
			  <MainLayout showModalType={showModalType} setShowModalType={setShowModalType}>
				<Error404/>
			 </MainLayout>
		  }/>

		  <Route exact path="/Altcoin-Season-Index" element={
			<MainLayout showModalType={showModalType} setShowModalType={setShowModalType}>
			  <Helmet>
				  <meta charSet="utf-8" />
				  <title>Altcoin Season Index</title>
				  <link rel="canonical" href="http://mysite.com/example" />
				  <meta
					name="description"
					content="90 day performance of altcoins versus BTC"
				  />
			  </Helmet>
				<AltcoinSeasonPage showModalType={showModalType} setShowModalType={setShowModalType}/>
			</MainLayout>
		  }/>

		  <Route exact path="/API-Dashboard" element={
			<MainLayout showModalType={showModalType} setShowModalType={setShowModalType}>
			  <Helmet>
				  <meta charSet="utf-8" />
				  <title>API Dashboard</title>
				  <link rel="canonical" href="http://mysite.com/example" />
				  <meta
					name="description"
					content="API Dashboard"
				  />
			  </Helmet>
				<APIDashboard setShowModalType={setShowModalType}/>
			</MainLayout>
		  }/>

		  <Route exact path="/payment-success" element={
			<MainLayout showModalType={showModalType} setShowModalType={setShowModalType}>
			  <Helmet>
				  <meta charSet="utf-8" />
				  <title>API Payments</title>
				  <link rel="canonical" href="http://mysite.com/example" />
				  <meta
					name="description"
					content="Payment Successful"
				  />
			  </Helmet>
				<PaymentSuccess/>
			</MainLayout>
		  }/>

		  <Route exact path="/payment-failed" element={
			<MainLayout showModalType={showModalType} setShowModalType={setShowModalType}>
			  <Helmet>
				  <meta charSet="utf-8" />
				  <title>API Payments</title>
				  <link rel="canonical" href="http://mysite.com/example" />
				  <meta
					name="description"
					content="Payment Failed"
				  />
			  </Helmet>
				<PaymentFailed/>
			</MainLayout>
		  }/>

        </Routes>
      </Router>
    </div>
  );
}

export default App;
