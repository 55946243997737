import React, { useEffect } from 'react';
import { useSectorIndexes } from '../endpoints/index'; // Adjust the import path as needed
import BFLoading from './small/BFLoading';

const SectorMiniWidgets = () => {
  const { data, taxonomyStats, isLoading } = useSectorIndexes('market_cap', 'desc');

  useEffect(() => {
    if (taxonomyStats) {
      // console.log('Received taxonomyStats:', taxonomyStats);
    }
  }, [taxonomyStats]);

  const getPerformanceColor = (value) => {
    if (value > 0.2) return 'bg-green-500';
    if (value < -0.2) return 'bg-red-500';
    return 'bg-gray-400'; // Neutral gray color for values between -0.2% and 0.2%
  };

  const renderWidget = (label, value1, value2, percentage1, percentage2, color1 = 'bg-green-500', color2 = 'bg-red-500') => (
    <div className="bg-white p-2 m-2 rounded shadow-md">
      <div className="flex justify-between items-center mb-1">
        <span className="text-lg font-bold text-gray-700">{label}</span>
      </div>
      <div className="flex justify-between items-center mb-1">
        <span className="text-xs text-gray-700">{`${value1} (${percentage1}%)`}</span>
        <span className="text-xs text-gray-700">{`${value2} (${percentage2}%)`}</span>
      </div>
      <div className="relative w-full h-2 bg-gray-200 mt-1">
        <div
          className={`absolute left-0 h-2 ${color1}`}
          style={{ width: `${percentage1}%` }}
        ></div>
        <div
          className={`absolute right-0 h-2 ${color2}`}
          style={{ width: `${percentage2}%` }}
        ></div>
      </div>
    </div>
  );

  if (isLoading) return <BFLoading />;

  if (taxonomyStats) {
    const totalAdvancing = taxonomyStats.asset_performance_24h_bullish + taxonomyStats.asset_performance_24h_bearish;
    const totalNewHighs = taxonomyStats.asset_new_highs + taxonomyStats.asset_new_lows;
    const total1yHighs = taxonomyStats.asset_1y_high + taxonomyStats.asset_1y_low;
    const totalSMA50 = taxonomyStats.asset_sma_50d_over + taxonomyStats.asset_sma_50d_under;
    const totalSMA200 = taxonomyStats.asset_sma_200d_over + taxonomyStats.asset_sma_200d_under;

    return (
      <div className="flex flex-col mb-4">
        {renderWidget(
          'Advancing / Declining',
          taxonomyStats.asset_performance_24h_bullish,
          taxonomyStats.asset_performance_24h_bearish,
          totalAdvancing ? ((taxonomyStats.asset_performance_24h_bullish / totalAdvancing) * 100).toFixed(1) : 0,
          totalAdvancing ? ((taxonomyStats.asset_performance_24h_bearish / totalAdvancing) * 100).toFixed(1) : 0,
          'bg-green-500',
          'bg-red-500'
        )}
        {renderWidget(
          '52 week high/low',
          taxonomyStats.asset_1y_high,
          taxonomyStats.asset_1y_low,
          total1yHighs ? ((taxonomyStats.asset_1y_high / total1yHighs) * 100).toFixed(1) : 0,
          total1yHighs ? ((taxonomyStats.asset_1y_low / total1yHighs) * 100).toFixed(1) : 0
        )}
        {renderWidget(
          'Above/Below SMA50',
          taxonomyStats.asset_sma_50d_over,
          taxonomyStats.asset_sma_50d_under,
          totalSMA50 ? ((taxonomyStats.asset_sma_50d_over / totalSMA50) * 100).toFixed(1) : 0,
          totalSMA50 ? ((taxonomyStats.asset_sma_50d_under / totalSMA50) * 100).toFixed(1) : 0
        )}
        {renderWidget(
          'Above/Below SMA200',
          taxonomyStats.asset_sma_200d_over,
          taxonomyStats.asset_sma_200d_under,
          totalSMA200 ? ((taxonomyStats.asset_sma_200d_over / totalSMA200) * 100).toFixed(1) : 0,
          totalSMA200 ? ((taxonomyStats.asset_sma_200d_under / totalSMA200) * 100).toFixed(1) : 0
        )}
      </div>
    );
  }

  return <div>No data available</div>;
};

export default SectorMiniWidgets;
