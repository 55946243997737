import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useSectorIndexes } from '../endpoints/index'; // Adjust the import path as needed
import BFLoading from './small/BFLoading';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const SectorBarChart = () => {
  const { data, isLoading } = useSectorIndexes('market_cap', 'desc');
  const [chartData, setChartData] = useState(null);
  const [toggleIndexType, setToggleIndexType] = useState('market_cap');
  const [toggleTimeframe, setToggleTimeframe] = useState('changepct_24hour');
  const [isSubsectorView, setIsSubsectorView] = useState(false);

  useEffect(() => {
    if (data) {
      updateChartData();
    }
  }, [data, toggleIndexType, toggleTimeframe]);

  const updateChartData = () => {
    const filteredData = data.filter((index) =>
      toggleIndexType === 'market_cap' ? !index.name.includes('EQW') : index.name.includes('EQW')
    );
    const sortedData = filteredData.sort((a, b) => b[toggleTimeframe] - a[toggleTimeframe]);

    const labels = sortedData.map((index) => index.name);
    const performance = sortedData.map((index) => index[toggleTimeframe]);

    setChartData({
      labels,
      datasets: [
        {
          label: getTimeframeLabel(toggleTimeframe),
          data: performance,
          backgroundColor: performance.map(value =>
            getBackgroundColor(value)
          ),
          borderColor: performance.map(value =>
            getBorderColor(value)
          ),
          borderWidth: 1,
        },
      ],
    });
    setIsSubsectorView(false);
  };

  const getTimeframeLabel = (timeframe) => {
    switch (timeframe) {
      case 'changepct_24hour':
        return '24h Performance (%)';
      case 'changepct_7d':
        return '7d Performance (%)';
      case 'changepct_1m':
        return '1 Month Performance (%)';
      case 'changepct_6m':
        return '6 Months Performance (%)';
      case 'changepct_1y':
        return '1 Year Performance (%)';
      default:
        return '';
    }
  };

  const getBackgroundColor = (value) => {
    if (value > 0.2) {
      return 'rgba(75, 192, 192, 0.2)'; // Light green
    } else if (value < -0.2) {
      return 'rgba(255, 99, 132, 0.2)'; // Light red
    } else {
      return 'rgba(128, 128, 128, 0.2)'; // Light gray for neutral values
    }
  };

  const getBorderColor = (value) => {
    if (value > 0.2) {
      return 'rgba(75, 192, 192, 1)'; // Dark green
    } else if (value < -0.2) {
      return 'rgba(255, 99, 132, 1)'; // Dark red
    } else {
      return 'rgba(128, 128, 128, 1)'; // Dark gray for neutral values
    }
  };

  const handleBarClick = (elements) => {
    if (elements.length === 0) return;

    const index = elements[0].index;
    const sectorName = chartData.labels[index];
    const selectedSector = data.find((index) => index.name === sectorName);

    if (selectedSector && selectedSector.subsectors) {
      const sortedSubsectors = selectedSector.subsectors.sort((a, b) => b.index[toggleTimeframe] - a.index[toggleTimeframe]);
      const subsectorLabels = sortedSubsectors.map((sub) => sub.index.name);
      const subsectorPerformance = sortedSubsectors.map((sub) => sub.index[toggleTimeframe]);

      setChartData({
        labels: subsectorLabels,
        datasets: [
          {
            label: getTimeframeLabel(toggleTimeframe),
            data: subsectorPerformance,
            backgroundColor: subsectorPerformance.map(value =>
              getBackgroundColor(value)
            ),
            borderColor: subsectorPerformance.map(value =>
              getBorderColor(value)
            ),
            borderWidth: 1,
          },
        ],
      });
      setIsSubsectorView(true);
    }
  };

  const handleBackClick = () => {
    updateChartData();
  };

  const handleToggleClick = (type) => {
    setToggleIndexType(type);
    updateChartData();
  };

  const handleTimeframeToggle = (timeframe) => {
    setToggleTimeframe(timeframe);
    updateChartData();
  };

  if (isLoading) return <BFLoading />;

  return (
    <div className="bg-main-white border-[1px] border-main-lightGrayBorder sm:rounded-2xl p-4 mb-4">
      <h2 className="text-lg font-semibold mb-4">Sector Performance</h2>
      <div className="flex justify-between items-center mb-4">
        <div>
          <button
            className={`px-4 py-2 mr-2 ${toggleIndexType === 'market_cap' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            onClick={() => handleToggleClick('market_cap')}
          >
            Market Cap
          </button>
          <button
            className={`px-4 py-2 ${toggleIndexType === 'equal_weight' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            onClick={() => handleToggleClick('equal_weight')}
          >
            Equal Weight
          </button>
          {isSubsectorView && (
            <button
              className="px-2 py-1 ml-2 bg-gray-200 text-sm"
              onClick={handleBackClick}
            >
              &lt; Back
            </button>
          )}
        </div>
        <div>
          <button
            className={`px-2 py-1 mr-2 text-sm ${toggleTimeframe === 'changepct_24hour' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            onClick={() => handleTimeframeToggle('changepct_24hour')}
          >
            1d
          </button>
          <button
            className={`px-2 py-1 mr-2 text-sm ${toggleTimeframe === 'changepct_7d' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            onClick={() => handleTimeframeToggle('changepct_7d')}
          >
            7d
          </button>
          <button
            className={`px-2 py-1 mr-2 text-sm ${toggleTimeframe === 'changepct_1m' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            onClick={() => handleTimeframeToggle('changepct_1m')}
          >
            1m
          </button>
          <button
            className={`px-2 py-1 mr-2 text-sm ${toggleTimeframe === 'changepct_6m' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            onClick={() => handleTimeframeToggle('changepct_6m')}
          >
            6m
          </button>
          <button
            className={`px-2 py-1 text-sm ${toggleTimeframe === 'changepct_1y' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            onClick={() => handleTimeframeToggle('changepct_1y')}
          >
            1y
          </button>
        </div>
      </div>
      {chartData ? (
        <div style={{ height: '300px' }}>
          <Bar
            data={chartData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              indexAxis: 'y',
              scales: {
                x: {
                  title: {
                    display: true,
                    text: getTimeframeLabel(toggleTimeframe),
                  },
                  ticks: {
                    callback: (value) => `${value}%`,
                    min: Math.floor(Math.min(...chartData.datasets[0].data)) - 1,
                    max: Math.ceil(Math.max(...chartData.datasets[0].data)) + 1,
                  },
                },
                y: {
                  title: {
                    display: true,
                    text: isSubsectorView ? 'Subsector' : 'Sector',
                  },
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
                title: {
                  display: true,
                  text: getTimeframeLabel(toggleTimeframe),
                },
                tooltip: {
                  callbacks: {
                    label: (context) => {
                      return `${context.dataset.label}: ${context.raw.toFixed(2)}%`;
                    },
                  },
                },
              },
              onClick: (event, elements) => handleBarClick(elements),
            }}
          />
        </div>
      ) : (
        <div>No data available</div>
      )}
    </div>
  );
};

export default SectorBarChart;
